import { onMount } from "solid-js";
import { AutopromosRenderer } from "~/components/shared/Autopromos/AutopromosRenderer";
import { gotoUrlWithUtm } from "~/utils/url";

export default function AutopromosS73() {
  onMount(() => {
    document.title = "Autopromos S73";
  });
  return (
    <>
      <section class="content-part">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p>
          <button
            class="btn"
            style={{ border: "1px solid black" }}
            onClick={() => gotoUrlWithUtm("http://www.breek.fr")}
          >
            Open url with params
          </button>
        </p>
        <AutopromosRenderer text="<h3>sponsorship-menu</h3>    <p>[sponsorship-menu]</p>" />
        <AutopromosRenderer text="<h3>promo-pinel-menu</h3>       [promo-pinel-menu]" />
        <AutopromosRenderer text="<h3>youtube</h3>    [youtube:https://www.youtube.com/embed/IMv7X1muLAw?si=gpNrLUQdKqUHG23b]" />
        <AutopromosRenderer text="<h3>magazine-teasers</h3>    [magazine-teasers]" />
        <AutopromosRenderer text="<h3>promo-parking-menu</h3>    [promo-parking-menu]" />
        <AutopromosRenderer text="<h3>promo-ptz-2024-menu</h3>    [promo-ptz-2024-menu]" />
        <AutopromosRenderer text="<h3>search</h3>    [search]" />
        <AutopromosRenderer text="<h3>promo-faq</h3>    [promo-faq]" />
        <AutopromosRenderer text="<h3>promo-guide-invest</h3>    [promo-guide-invest]" />
        <AutopromosRenderer text="<h3>promo-financial-study</h3>    [promo-financial-study]" />

        <AutopromosRenderer text="<h3>tools</h3>    [tools]" />
        <AutopromosRenderer text="<h3>promo-form_advisor</h3>    [promo-form_advisor]" />
        <AutopromosRenderer text="<h3>promo-simulator-lmnp-bouvard-1</h3>    [promo-simulator_lmnp-bouvard-1]" />
        <AutopromosRenderer text="<h3>promo-simulator_ptz-2</h3>    [promo-simulator_ptz-2]" />
        <AutopromosRenderer text="<h3>escda</h3>    [escda]" />
        <AutopromosRenderer text="<h3>sponsorship</h3>    [sponsorship]" />
        <AutopromosRenderer text="<h3>areas</h3>    [promo-areas]" />
        <AutopromosRenderer text="<h3>form-in-page</h3>    [form-in-page]" />
      </section>

      <section
        data-ga-zone="ads"
        data-test="autopromos"
        class="autopromos-container multiple"
      >
        <AutopromosRenderer text="<h3>search</h3>    [search]" />
        <AutopromosRenderer text="<h3>promo-faq</h3>    [promo-faq]" />
        <AutopromosRenderer text="<h3>promo-guide-invest</h3>    [promo-guide-invest]" />
        <AutopromosRenderer text="<h3>promo-tools</h3>    [tools]" />
        <AutopromosRenderer text="<h3>promo-form_advisor</h3>    [promo-form_advisor]" />
        <AutopromosRenderer text="<h3>promo-simulator_lmnp-bouvard-1</h3>    [promo-simulator_lmnp-bouvard-1]" />
        <AutopromosRenderer text="<h3>promo-simulator_ptz-2</h3>    [promo-simulator_ptz-2]" />
      </section>
    </>
  );
}
